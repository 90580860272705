/** @jsx jsx */

import { Layout, ContactForm } from "components";
import { Text, Heading, jsx, Flex } from "theme-ui";
import { center } from "utils";

const Erfolg = () => {
  return (
    <Layout>
      <Flex
        sx={{
          gridColumn: center,
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "700px",
          my: [4, 6],
        }}
      >
        <Heading as="h2" sx={{ fontSize: 6, my: 3 }}>
          Vielen Dank!
        </Heading>
        <Text sx={{ fontFamily: "body", fontSize: 4 }}>
          Ihre Buchung war erfolgreich.
        </Text>
        <ContactForm sx={{ my: [4, 6] }}>
          <Heading as="h3" sx={{ my: 3, fontSize: 4 }}>
            Kontakt
          </Heading>
          <Text variant="default">
            Sollten Sie Fragen, Probleme oder Kritik für uns haben, schreiben
            sie uns gerne. Um Ihre Buchung zuordnen zu können geben Sie bitte
            den Name und die E-Mail-Adresse an die Sie bei der Buchung verwendet
            haben.
          </Text>
        </ContactForm>
      </Flex>
    </Layout>
  );
};

export default Erfolg;
